<template>
  <div class="pin" @click="enter">
    <img v-if="tower.isEnabled" src="@/assets/icons/tower.png" alt="" />
    <img v-else src="@/assets/icons/tower-red.png" alt="" />
    <div :class="{ 'is-enabled': tower.isEnabled }">
      <span>{{ tower.name.toLowerCase().toTitleCase() }}</span>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import "mapbox-gl/dist/mapbox-gl.css";
import "@gouch/to-title-case";

export default {
  props: ["tower", "map"],
  methods: {
    enter() {
      if (this.tower.isEnabled)
        this.$router.push({
          name: "tower.details",
          params: {
            id: this.tower.id,
          },
        });
    },
  },
  mounted() {
    new mapboxgl.Marker(this.$el)
      .setLngLat([this.tower.lng, this.tower.lat])
      .addTo(this.map);
  },
};
</script>