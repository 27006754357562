<template>
  <div id="page-tower">
    <div id="tower-map" class="h-100 v-100"></div>
    <div v-if="towers.length > 0">
      <pin
        v-for="tower in towers"
        :key="tower.id"
        :tower="tower"
        :map="map"
      ></pin>
    </div>
    <div id="tower-list-container" v-if="towers.length > 0">
      <div id="tower-list-search">
        <i class="fas fa-search icon-search"></i>
        <input type="text" v-model="query" placeholder="Type here to search" />
      </div>
      <div id="tower-list">
        <ul class="list-group list-group-flush">
          <li
            v-for="tower in filteredTowers"
            class="list-group-item p-0"
            :class="{ 'is-enabled': tower.isEnabled }"
            :key="tower.id"
          >
            <router-link
              :to="
                tower.isEnabled
                  ? {
                      name: 'tower.details',
                      params: {
                        id: tower.id,
                      },
                    }
                  : { name: 'towers' }
              "
            >
              <div>
                <p>{{ tower.name.toLowerCase().toTitleCase() }}</p>
                <p>{{ tower.lat }}, {{ tower.lng }}</p>
              </div>
              <i class="fas fa-chevron-right"></i>
            </router-link>
          </li>
          <li
            class="list-group-item text-center"
          ><i class="small text-muted">End of list</i></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import "mapbox-gl/dist/mapbox-gl.css";
import API from "@/api";
import Pin from "@/components/Pin";

export default {
  name: "towers",
  components: {
    pin: Pin,
  },
  data() {
    return {
      map: null,
      towers: [],
      query: "",
    };
  },
  computed: {
    filteredTowers() {
      if (this.query == "") {
        return this.towers;
      }
      return this.towers.filter((tower) => {
        return tower.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
  },
  methods: {
    initMap() {
      return new Promise((resolve, reject) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        this.map = new mapboxgl.Map({
          container: "tower-map",
          style: "mapbox://styles/mapbox/dark-v9",
          center: [101.6869, 3.139],
          zoom: 10,
        });

        this.map.on("load", () => {
          this.getTowers();
          resolve();
        });
      });
    },
    getTowers() {
      API.get("sites")
        .then((retVal) => {
          this.towers = retVal.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    document.title = "Towers | magardTELE";
    this.initMap();
  },
};
</script>